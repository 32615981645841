// REDUX REDUCERS
import {
  FETCH_LESSON,
  FETCH_LESSON_SUCCESS,
  FETCH_LESSON_FAILURE
} from "../constants";

const initialState = {
  lesson: [
    {
      data: [],
      error: null,
      loading: false
    }
  ],
  lessons: [
    {
      data: [],
      error: null,
      loading: false
    }
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LESSON:
      return { ...state, lesson: { data: [], error: null, loading: true } };

    case FETCH_LESSON_SUCCESS:
      return {
        ...state,
        lesson: { data: action.payload, error: null, loading: false }
      };

    case FETCH_LESSON_FAILURE:
      return {
        ...state,
        lesson: { data: [], error: action.payload, loading: false }
      };

    default:
      return state;
  }
};
