import {
  SET_SECONDARY_NAVBAR,
  RESET_SECONDARY_NAVBAR,
  SET_SECONDARY_NAVBAR_LEFT,
  RESET_SECONDARY_NAVBAR_LEFT,
  SET_SECONDARY_NAVBAR_RIGHT,
  RESET_SECONDARY_NAVBAR_RIGHT
} from "../constants";

const initialState = {
  drawerEnabled: false,
  drawerOpen: false,
  header: [
    {
      title: null
    }
  ],
  secondaryNavbar: null,
  secondaryNavbarRight: null,
  modalType: "none"
};

function layoutReducer(state = initialState, action) {
  switch (action.type) {
    /*
    case SET_SECONDARY_NAVBAR:
      return { ...state, secondaryNavbar: action.payload };

    case RESET_SECONDARY_NAVBAR:
      return { ...state, secondaryNavbar: null };

    case SET_SECONDARY_NAVBAR_RIGHT:
      return { ...state, secondaryNavbarRight: action.payload };

    case RESET_SECONDARY_NAVBAR_RIGHT:
      return { ...state, secondaryNavbarRight: null };
    */
    default:
      return state;
  }
}

export default layoutReducer;
