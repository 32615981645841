import {
  FETCH_CONTACTS,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_GROUPS,
  FETCH_CONTACTS_GROUPS_SUCCESS,
  FETCH_CONTACTS_GROUPS_FAILURE,
  RESET_CONTACTS
} from "../constants.js";

const initialState = {
  contacts: [],
  groups: [],
  group: {},
  error: null,
  loading_c: false, // loading status for contacts
  loading_g: false // loading status for contact groups
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACTS:
      return {
        ...state,
        //contacts: [],
        //group: {},
        error: null,
        loading_c: true
      };

    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload.contacts,
        group: action.payload.group,
        error: null,
        loading_c: false
      };

    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        contacts: [],
        group: {},
        error: action.payload,
        loading_c: false
      };

    case FETCH_CONTACTS_GROUPS:
      return {
        ...state,
        groups: [],
        error: null,
        loading_g: true
      };

    case FETCH_CONTACTS_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        error: null,
        loading_g: false
      };

    case FETCH_CONTACTS_GROUPS_FAILURE:
      return {
        ...state,
        groups: [],
        error: action.payload,
        loading_g: false
      };

    case RESET_CONTACTS:
      return {
        ...state,
        contacts: [],
        groups: [],
        error: null,
        loading_c: false,
        loading_g: false
      };

    default:
      return state;
  }
};
