import React from "react";
import ReactDOM from "react-dom";
import "./i18n";

import { Provider } from "react-redux";

import history from "./history";

import configureStore from "./store/store";
//import { configureServices } from "./services";
import context from "./context";

import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";

const loadApp = async () => {
  const module = await import("./components/App");
  return module.default;
};

const render = async store => {
  const target = document.getElementById("root");
  const App = await loadApp();

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          autoHideDuration={2000}
        >
          <App />
        </SnackbarProvider>
      </ConnectedRouter>
    </Provider>,
    target
  );
};

(async function init() {
  //const services = await configureServices();
  //const { actions, reducers } = await configureModules(services);

  //context.registerServices(services);
  //context.registerActions(actions);

  //render(configureStore(reducers));
  render(configureStore);
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
