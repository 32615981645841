// REDUX REDUCERS
import {
  FETCH_TIMELINE,
  FETCH_TIMELINE_SUCCESS,
  FETCH_TIMELINE_ERROR
} from "../constants.js";

const initialState = {
  cid: null,
  data: [],
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIMELINE:
      return { ...state, loading: true };

    case FETCH_TIMELINE_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };

    case FETCH_TIMELINE_ERROR:
      return { ...state, ...action.payload, loading: false };

    default:
      return state;
  }
};
