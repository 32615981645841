// REDUX REDUCERS
import {
  FETCH_CURRICULUM,
  FETCH_CURRICULUM_SUCCESS,
  FETCH_CURRICULUM_FAILURE,
  FETCH_CURRICULUM_LIST,
  FETCH_CURRICULUM_LIST_SUCCESS,
  FETCH_CURRICULUM_LIST_FAILURE,
  REORDER_LESSON_ITEMS,
  REORDER_LESSON_ITEMS_SUCCESS,
  REORDER_LESSON_ITEMS_FAILURE,
  SET_CURRICULUM_ID
} from "../constants.js";

const initialState = {
  error_list: null,
  loading_list: false,
  list: [],
  list_checksum: null,
  data: { title: "" },
  error_single: null,
  single_loading: false,
  single: [],
  curr_id: null
};

function getcurriculum(list, id) {
  let datac = {};
  let data2 = list.filter(cc => cc.id == id);
  if (data2.length == 1) {
    datac = data2[0];
  }

  //console.log ('curr state ::: ', list );
  //console.log ('found data ::: ', datac);

  return datac;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRICULUM:
      return { ...state, single_loading: true, error_single: null };

    case FETCH_CURRICULUM_SUCCESS:
      return {
        ...state,
        single: action.payload,
        error_single: null,
        single_loading: false
      };

    case FETCH_CURRICULUM_FAILURE: // return error and make loading = false
      return {
        ...state,
        single: null,
        error_single: action.payload,
        single_loading: false
      };

    case FETCH_CURRICULUM_LIST:
      return { ...state, loading_list: true, error_list: null };

    case FETCH_CURRICULUM_LIST_SUCCESS:
      let datac = getcurriculum(action.payload.list, state.id);
      //console.log('datac', datac);
      return {
        ...state,
        list: action.payload.list,
        list_checksum: action.payload.checksum,
        error_list: null,
        loading_list: false,
        data: datac
      };

    case FETCH_CURRICULUM_LIST_FAILURE: // return error and make loading = false
      return {
        ...state,
        list: null,
        error_list: action.payload,
        loading_list: false
      };

    case REORDER_LESSON_ITEMS:
      return {
        ...state,
        single: action.payload,
        error_single: null,
        single_loading: false
      };

    case SET_CURRICULUM_ID: {
      let datac = getcurriculum(state.list, action.payload);
      //console.log('datac', datac);
      return { ...state, id: action.payload, data: datac };
    }

    default:
      return state;
  }
};
