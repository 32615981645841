import {
  FETCH_COURSE,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILURE,
  FETCH_COURSES,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILURE,
  FETCH_COURSE_CURRICULUM,
  FETCH_COURSE_CURRICULUM_SUCCESS,
  FETCH_COURSE_CURRICULUM_FAILURE
} from "../constants.js";

const initialStateCourses = {
  list: [], // list of courses
  loading: false,
  error: null
};

const initialStateCourse = {
  data: {}, // course data
  members: [],
  subgroups: [],
  loading: false,
  error: null,
  curriculum: [],
  curriculum_data: []
};

// COURSES - LIST
const coursesReducer = (state = initialStateCourses, action) => {
  switch (action.type) {
    case FETCH_COURSES:
      return { ...state, loading: true, error: null };

    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error: null,
        loading: false
      };

    case FETCH_COURSES_FAILURE:
      return {
        ...state,
        list: [],
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

// COURSE - SINGLE

const courseReducer = (state = initialStateCourse, action) => {
  switch (action.type) {
    case FETCH_COURSE:
      return { ...state, loading: true, error: null };

    case FETCH_COURSE_SUCCESS:
      return {
        ...state,
        data: action.payload.course,
        members: action.payload.members,
        subgroups: action.payload.subgroups,
        error: null,
        loading: false
      };

    case FETCH_COURSE_FAILURE:
      return {
        ...state,
        data: {},
        members: [],
        error: action.payload,
        loading: false,
        curriculum: []
      };
    case FETCH_COURSE_CURRICULUM:
      return { ...state, curriculum: { data: [], error: null, loading: true } };

    case FETCH_COURSE_CURRICULUM_SUCCESS:
      return {
        ...state,
        curriculum: action.payload.curriculum,
        curriculum_data: action.payload.data
      };

    case FETCH_COURSE_CURRICULUM_FAILURE:
      return {
        ...state,
        curriculum: [],
        curriculum_data: []
      };
    default:
      return state;
  }
};

export { coursesReducer as courses, courseReducer as course };
