//ACTIVITY

export const FETCH_ACTIVITY_SINGLE = "FETCH_ACTIVITY_SINGLE";
export const FETCH_ACTIVITY_SINGLE_SUCCESS = "FETCH_ACTIVITY_SINGLE_SUCCESS";
export const FETCH_ACTIVITY_SINGLE_FAILURE = "FETCH_ACTIVITY_SINGLE_FAILURE";
export const FETCH_ACTIVITY = "FETCH_ACTIVITY";
export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS";
export const FETCH_ACTIVITY_FAILURE = "FETCH_ACTIVITY_FAILURE";
export const MARK_ACTIVITY_AS_VIEWED = "MARK_ACTIVITY_AS_VIEWED";

// APPLICATION
//
export const FETCH_APPLICATION_PROFILE = "FETCH_APPLICATION_PROFILE";
export const FETCH_APPLICATION_PROFILE_SUCCESS =
  "FETCH_APPLICATION_PROFILE_SUCCESS";
export const FETCH_APPLICATION_PROFILE_FAILURE =
  "FETCH_APPLICATION_PROFILE_FAILURE";
export const APP_SET_ONLINE = "APP_SET_OFFLINE";

// ACCOUNT
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const RESET_ME_A = "RESET_ME_A";
export const ME_FROM_TOKEN = "ME_FROM_TOKEN";
export const ME_FROM_TOKEN_SUCCESS = "ME_FROM_TOKEN_SUCCESS";
export const ME_FROM_TOKEN_FAILURE = "ME_FROM_TOKEN_FAILURE";
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNT_FAILURE = "FETCH_ACCOUNT_FAILURE";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

// CONTACTS

export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_FAILURE = "FETCH_CONTACTS_FAILURE";
export const FETCH_CONTACTS_GROUPS = "FETCH_CONTACTS_GROUPS";
export const FETCH_CONTACTS_GROUPS_SUCCESS = "FETCH_CONTACTS_GROUPS_SUCCESS";
export const FETCH_CONTACTS_GROUPS_FAILURE = "FETCH_CONTACTS_GROUPS_FAILURE";
export const RESET_CONTACTS = "RESET_CONTACTS";

// COURSES
export const RESET_COURSE = "RESET_COURSE";
export const FETCH_COURSE = "FETCH_COURSE";
export const FETCH_COURSE_SUCCESS = "FETCH_COURSE_SUCCESS";
export const FETCH_COURSE_FAILURE = "FETCH_COURSE_FAILURE";
export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_FAILURE = "FETCH_COURSES_FAILURE";
export const FETCH_COURSE_CURRICULUM = "FETCH_COURSE_CURRICULUM";
export const FETCH_COURSE_CURRICULUM_SUCCESS =
  "FETCH_COURSE_CURRICULUM_SUCCESS";
export const REORDER_COURSE_CURRICULUM_FAILURE =
  "FETCH_COURSE_CURRICULUM_FAILURE";
export const REORDER_COURSE_CURRICULUM = "REORDER_COURSE_CURRICULUM";
export const REORDER_COURSE_CURRICULUM_SUCCESS =
  "REORDER_COURSE_CURRICULUM_SUCCESS";
export const FETCH_COURSE_CURRICULUM_FAILURE =
  "FETCH_COURSE_CURRICULUM_FAILURE";

// NAVBAR
export const SET_SECONDARY_NAVBAR = "SET_SECONDARY_NAVBAR";
export const RESET_SECONDARY_NAVBAR = "RESET_SECONDARY_NAVBAR";
export const SET_SECONDARY_NAVBAR_TITLE = "SET_SECONDARY_NAVBAR_TITLE";
export const RESET_SECONDARY_NAVBAR_TITLE = "RESET_SECONDARY_NAVBAR_TITLE";
export const SET_SECONDARY_NAVBAR_LEFT = "SET_SECONDARY_NAVBAR_LEFT";
export const RESET_SECONDARY_NAVBAR_LEFT = "RESET_SECONDARY_NAVBAR_LEFT";
export const SET_SECONDARY_NAVBAR_RIGHT = "SET_SECONDARY_NAVBAR_RIGHT";
export const RESET_SECONDARY_NAVBAR_RIGHT = "RESET_SECONDARY_NAVBAR_RIGHT";

// MEET
export const OPEN_MEET = "OPEN_MEET";
export const CLOSE_MEET = "CLOSE_MEET";
export const FETCH_MEET_LINKS = "FETCH_MEET_LINKS";
export const CLEAR_MEET_LINKS = "CLEAR_MEET_LINKS";
export const MEET_LINKS_LOADING = "MEET_LINKS_LOADING";

// LAYOUT
export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const SIDEBAR_CLOSE = "SIDEBAR_CLOSE";
export const SIDEBAR_LEFT_OPEN = "SIDEBAR_LEFT_OPEN";
export const SIDEBAR_LEFT_CLOSE = "SIDEBAR_LEFT_CLOSE";
export const SIDEBAR_RIGHT_OPEN = "SIDEBAR_RIGHT_OPEN";
export const SIDEBAR_RIGHT_CLOSE = "SIDEBAR_RIGHT_CLOSE";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const UPDATE_DIALOG = "UPDATE_DIALOG";

// GROUPS
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";
export const RESET_GROUPS = "RESET_GROUPS";
export const SET_GROUP_TAB = "GROUP_TAB";
export const RESET_GROUP = "RESET_GROUP";
export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS";
export const FETCH_GROUP_FAILURE = "FETCH_GROUP_FAILURE";
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const FETCH_GROUP_CURRICULUM = "FETCH_GROUP_CURRICULUM";
export const FETCH_GROUP_CURRICULUM_SUCCESS = "FETCH_GROUP_CURRICULUM_SUCCESS";
export const FETCH_GROUP_CURRICULUM_FAILURE = "FETCH_GROUP_CURRICULUM_FAILURE";

// PROJECTS
export const FETCH_PROJECT = "FETCH_PROJECT";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILURE = "FETCH_PROJECT_FAILURE";
export const SET_PROJECT_TAB = "SET_PROJECT_TAB";
export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";
export const CREATE_ELEMENT = "CREATE_ELEMENT";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const UPDATE_CARD = "UPDATE_CARD";
export const FETCH_PROJECT_TIMELINE = "FETCH_PROJECT_TIMELINE";
export const FETCH_PROJECT_TIMELINE_SUCCESS = "FETCH_PROJECT_TIMELINE_SUCCESS";

// LESSON
export const FETCH_LESSON = "FETCH_LESSON";
export const FETCH_LESSON_SUCCESS = "FETCH_LESSON_SUCCESS";
export const FETCH_LESSON_FAILURE = "FETCH_LESSON_FAILURE";

// CONTENT
export const FETCH_CONTENT_LIST = "FETCH_CONTENT_LIST";
export const FETCH_CONTENT_LIST_SUCCESS = "FETCH_CONTENT_LIST_SUCCESS";
export const FETCH_CONTENT_LIST_FAILURE = "FETCH_CONTENT_LIST_FAILURE";
export const FETCH_CONTENT = "FETCH_CONTENT";
export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS";
export const FETCH_CONTENT_FAILURE = "FETCH_CONTENT_FAILURE";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
export const DELETE_CONTENT_FAILURE = "DELETE_CONTENT_FAILURE";
export const DUPLICATE_CONTENT = "DUPLICATE_CONTENT";
export const DUPLICATE_CONTENT_SUCCESS = "DUPLICATE_CONTENT_SUCCESS";
export const DUPLICATE_CONTENT_FAILURE = "DUPLICATE_CONTENT_FAILURE";
export const CONTENT_LIST_FILTER = "CONTENT_LIST_FILTER";

// CURRICULUM
export const FETCH_CURRICULUM = "FETCH_CURRICULUM";
export const FETCH_CURRICULUM_SUCCESS = "FETCH_CURRICULUM_SUCCESS";
export const FETCH_CURRICULUM_FAILURE = "FETCH_CURRICULUM_FAILURE";
export const FETCH_CURRICULUM_LIST = "FETCH_CURRICULUM_LIST";
export const FETCH_CURRICULUM_LIST_SUCCESS = "FETCH_CURRICULUM_LIST_SUCCESS";
export const FETCH_CURRICULUM_LIST_FAILURE = "FETCH_CURRICULUM_LIST_FAILURE";
export const REORDER_LESSON_ITEMS = "REORDER_LESSON_ITEMS";
export const REORDER_LESSON_ITEMS_SUCCESS = "REORDER_LESSON_ITEMS_SUCCESS";
export const REORDER_LESSON_ITEMS_FAILURE = "REORDER_LESSON_ITEMS_FAILURE";
export const SET_CURRICULUM_ID = "SET_CURRICULUM_ID";

// DRIVE
export const SEARCH_DRIVE = "SEARCH_DRIVE";
export const FETCH_DRIVE = "FETCH_DRIVE";
export const FETCH_DRIVE_SUCCESS = "FETCH_DRIVE_SUCCESS";
export const FETCH_DRIVE_FAILURE = "FETCH_DRIVE_FAILURE";
export const SET_SELECTED_DRIVE = "SET_SELECTED_DRIVE";

// MESSENGER
export const FETCH_CHANNEL = "FETCH_CHANNEL";
export const FETCH_CHANNEL_SUCCESS = "FETCH_CHANNEL_SUCCESS";
export const FETCH_CHANNEL_FAILURE = "FETCH_CHANNEL_FAILURE";

export const FETCH_CHANNEL_LIST = "FETCH_CHANNEL_LIST";
export const FETCH_CHANNEL_LIST_SUCCESS = "FETCH_CHANNEL_LIST_SUCCESS";
export const FETCH_CHANNEL_LIST_FAILURE = "FETCH_CHANNEL_LIST_FAILURE";

export const CREATE_CHANNEL = "CREATE_CHANNEL";
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const CREATE_CHANNEL_FAILURE = "CREATE_CHANNEL_FAILURE";

// NOTES
export const FETCH_NOTES = "FETCH_NOTES";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAILURE = "FETCH_NOTES_FAILURE";
export const RESET_NOTES = "RESET_NOTES";

//TASKS
export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILURE = "FETCH_TASKS_FAILURE";
export const FETCH_TASK = "FETCH_TASK";
export const FETCH_TASK_SUCCESS = "FETCH_TASK_SUCCESS";
export const FETCH_TASK_FAILURE = "FETCH_TASK_FAILURE";
export const SET_TASKS_VIEW = "SET_TASKS_VIEW";

// CALENDAR
export const FETCH_CALENDAR = "FETCH_CALENDAR";
export const FETCH_CALENDAR_SUCCESS = "FETCH_CALENDAR_SUCCESS";
export const FETCH_CALENDAR_FAILURE = "FETCH_CALENDAR_FAILURE";
export const RESET_CALENDAR = "RESET_CALENDAR";

// TIMELINE
export const FETCH_TIMELINE = "FETCH_TIMELINE";
export const FETCH_TIMELINE_SUCCESS = "FETCH_TIMELINE_SUCCESS";
export const FETCH_TIMELINE_ERROR = "FETCH_TIMELINE_ERROR";

// TAXONOMY
export const FETCH_TAXONOMY = "FETCH_TAXONOMY";
export const FETCH_TAXONOMY_SUCCESS = "FETCH_TAXONOMY_SUCCESS";
export const FETCH_TAXONOMY_FAILURE = "FETCH_TAXONOMY_FAILURE";
