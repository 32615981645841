import {
  FETCH_NOTES,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
  RESET_NOTES
} from "../constants";

const initialState = {
  notes: [],
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTES:
      return {
        ...state,
        notes: [],
        error: null,
        loading: true
      };

    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        error: null,
        loading: false
      };

    case FETCH_NOTES_FAILURE:
      return {
        ...state,
        notes: [],
        error: action.payload,
        loading: false
      };
    case RESET_NOTES:
      return {
        ...state,
        ntoes: [],
        error: null,
        loading: false
      };

    default:
      return state;
  }
};
