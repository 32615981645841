import {
  FETCH_TASK,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAILURE,
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
  SET_TASKS_VIEW
} from "../constants";

import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";

const initialState = {
  list: [],
  task: [],
  view: "user-list", // user-list, user-task, manager-list, manager-task
  error: null,
  loading: false,

  status_list: status => {
    let statusList = [
      {
        type: "pending",
        btnColor: "bg-blue",
        icon: "icon-menu",
        iconm: "hourglass_empty",
        text: "Pending",
        textColor: blue[500],
        sum: 0
      },
      {
        type: "in-progress",
        btnColor: "bg-orange",
        icon: "icon-watch2",
        iconm: "watch",
        text: "In progress",
        textColor: orange[500],
        sum: 0
      },
      {
        type: "refused",
        textColor: red[500],
        btnColor: "bg-warning",
        icon: "icon-cross2",
        iconm: "cancel",
        text: "Refused",
        sum: 0
      },
      {
        type: "completed",
        textColor: green[500],
        btnColor: "bg-success",
        icon: "icon-checkmark3",
        iconm: "check_circle_outline",
        text: "Completed",
        sum: 0
      }
    ];

    if (status) {
      let result = statusList.find(ty => ty.type === status);

      if (result === undefined) {
        return {
          type: status,
          textColor: "text-default",
          btnColor: "bg-grey",
          icon: "icon-menu",
          text: "Unknown",
          sum: 0
        };
      }

      return result;
    } else {
      return statusList;
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TASKS_VIEW:
      return { ...state, view: action.view, error: null, loading: false };

    case FETCH_TASKS:
      return { ...state, list: [], error: null, loading: true };

    case FETCH_TASKS_SUCCESS: {
      return { ...state, list: action.payload, error: null, loading: false };
    }

    case FETCH_TASKS_FAILURE: {
      let error;
      error = action.payload || { message: action.payload.message };
      return { ...state, list: [], error: error, loading: false };
    }

    case FETCH_TASK:
      return { ...state, task: [], error: null, loading: true };

    case FETCH_TASK_SUCCESS: {
      return { ...state, task: action.payload, error: null, loading: false };
    }

    case FETCH_TASK_FAILURE: {
      let error;
      error = action.payload || { message: action.payload.message };
      return { ...state, task: [], error: error, loading: false };
    }

    default:
      return state;
  }
};
//export default rootReducer;
