import {
  SET_SECONDARY_NAVBAR,
  RESET_SECONDARY_NAVBAR,
  SET_SECONDARY_NAVBAR_TITLE,
  RESET_SECONDARY_NAVBAR_TITLE,
  SET_SECONDARY_NAVBAR_LEFT,
  RESET_SECONDARY_NAVBAR_LEFT,
  SET_SECONDARY_NAVBAR_RIGHT,
  RESET_SECONDARY_NAVBAR_RIGHT
} from "../constants";

const initialState = {
  // for future use
  primary: {
    title: null,
    left: null,
    right: null
  },
  secondary: {
    title: null,
    left: null,
    right: null
  }
};

function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECONDARY_NAVBAR:
      return { ...state, secondary: action.payload };

    case RESET_SECONDARY_NAVBAR:
      return { ...state, secondary: initialState.secondary };

    case SET_SECONDARY_NAVBAR_TITLE:
      return {
        ...state,
        secondary: { ...state.secondary, title: action.payload }
      };

    case RESET_SECONDARY_NAVBAR_TITLE:
      return { ...state, secondary: { ...state.secondary, title: null } };

    case SET_SECONDARY_NAVBAR_LEFT:
      return {
        ...state,
        secondary: { ...state.secondary, left: action.payload }
      };

    case RESET_SECONDARY_NAVBAR_LEFT:
      return { ...state, secondary: { ...state.secondary, left: null } };

    case SET_SECONDARY_NAVBAR_RIGHT:
      return {
        ...state,
        secondary: { ...state.secondary, right: action.payload }
      };

    case RESET_SECONDARY_NAVBAR_RIGHT:
      return { ...state, secondary: { ...state.secondary, right: null } };

    default:
      return state;
  }
}

export default layoutReducer;
