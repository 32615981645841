import {
  FETCH_CALENDAR,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_FAILURE,
  RESET_CALENDAR
} from "../constants";

const initialState = {
  events: [],
  dateFrom: null,
  dateTo: null,
  filters: [],
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALENDAR: // start fetching posts and set loading = true
      return {
        ...state,
        events: [],
        error: null,
        loading: true,
        dateFrom: null,
        dateTo: null
      };

    case FETCH_CALENDAR_SUCCESS: {
      // return list of posts and make loading = false
      //console.log('payload', action);
      return {
        ...state,
        events: action.payload.events,
        dateFrom: action.payload.dateFrom,
        dateTo: action.payload.dateTo,
        error: null,
        loading: false
      };
    }

    case FETCH_CALENDAR_FAILURE: // return error and make loading = false
      return {
        ...state,
        events: [],
        error: action.payload,
        dateFrom: null,
        dateTo: null,
        oading: false
      };

    case RESET_CALENDAR: // start fetching posts and set loading = true
      return {
        ...state,
        events: [],
        filters: [],
        error: null,
        loading: false,
        dateFrom: null,
        dateTo: null
      };

    default:
      return state;
  }
};
//export default rootReducer;
