import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import application from "./reducers/application";
import activity from "./reducers/activity";
import account from "./reducers/account";
import calendar from "./reducers/calendar";
import contacts from "./reducers/contacts";
import content from "./reducers/content";
import { courses, course } from "./reducers/courses";
import curriculum from "./reducers/curriculum";
import drive from "./reducers/drive";
import dialog from "./reducers/dialog";
import groups from "./reducers/groups";
import layout from "./reducers/layout";
import messenger from "./reducers/messenger";
import modal from "./reducers/modal";
import lesson from "./reducers/lesson";
import project from "./reducers/project";
import tasks from "./reducers/tasks";
import timeline from "./reducers/timeline";
import sidebar from "./reducers/sidebar";
import navbar from "./reducers/navbar";
import notes from "./reducers/notes";
import meet from "./reducers/meet";
//import taxonomy from "./reducers/taxonomy";

export default history =>
  combineReducers({
    router: connectRouter(history),
    application,
    activity,
    account,
    drive,
    dialog,
    calendar,
    contacts,
    content,
    course,
    courses,
    curriculum,
    groups,
    lesson,
    layout,
    meet,
    messenger,
    modal,
    navbar,
    notes,
    sidebar,
    timeline,
    tasks,
    project
  });
