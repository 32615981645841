// REDUX REDUCERS

import {
  FETCH_GROUPS,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FAILURE,
  CREATE_GROUP,
  //CREATE_GROUP_SUCCESS,
  //CREATE_GROUP_FAILURE,
  RESET_GROUPS,
  RESET_GROUP,
  SET_GROUP_TAB,
  FETCH_GROUP_CURRICULUM,
  FETCH_GROUP_CURRICULUM_SUCCESS,
  FETCH_GROUP_CURRICULUM_FAILURE
} from "../constants";

const initialState = {
  groupsList: {
    groups: [],
    error: null,
    loading: false
  },
  group: {
    tab: "stream",
    data: {},
    error: null,
    loading: false
  },
  curriculum: {
    data: [],
    error: null,
    loading: false
  },
  lessonList: [
    {
      data: [],
      error: null,
      loading: false
    }
  ],
  lessonSingle: [
    {
      data: [],
      error: null,
      loading: false
    }
  ]
};

export default (state = initialState, action) => {
  //console.log('reducer GRP ', action);

  switch (action.type) {
    case RESET_GROUPS:
      return initialState;

    case CREATE_GROUP:
      return { ...state, group: { data: {}, error: null, saving: true } };

    case FETCH_GROUPS:
      return {
        ...state,
        groupsList: { groups: [], error: null, loading: true }
      };

    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groupsList: { groups: action.payload, error: null, loading: false }
      };

    case FETCH_GROUPS_FAILURE:
      let error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        ...state,
        groupsList: { groups: [], error: error, loading: false }
      };

    case FETCH_GROUP:
      return {
        ...state,
        group: { data: {}, error: null, loading: true, tab: "stream" }
      };

    case FETCH_GROUP_SUCCESS:
      return {
        ...state,
        group: {
          data: action.payload,
          error: null,
          loading: false,
          tab: "stream"
        }
      };

    case FETCH_GROUP_FAILURE: {
      let error;
      error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        ...state,
        group: { data: {}, error: error, loading: false, tab: "stream" }
      };
    }
    case RESET_GROUP:
      return {
        ...state,
        group: { data: {}, error: null, loading: false, tab: "stream" }
      };

    case SET_GROUP_TAB:
      return {
        ...state,
        group: {
          data: state.group.data,
          error: null,
          loading: false,
          tab: action.payload
        }
      };

    case FETCH_GROUP_CURRICULUM:
      return { ...state, curriculum: { data: [], error: null, loading: true } };

    case FETCH_GROUP_CURRICULUM_SUCCESS:
      return {
        ...state,
        curriculum: { data: action.payload, error: null, loading: false }
      };

    case FETCH_GROUP_CURRICULUM_FAILURE:
      return {
        ...state,
        curriculum: { data: [], error: action.payload, loading: false }
      };
    default:
      return state;
  }
};
//export default rootReducer;
