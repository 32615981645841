import React from "react";

import IconAll from "@material-ui/icons/AllInclusive";
import IconAlarm from "@material-ui/icons/AccessAlarm";
import IconAlarmAdd from "@material-ui/icons/AlarmAdd";
import IconAccount from "@material-ui/icons/AccountCircle";
import IconAdd from "@material-ui/icons/Add";
import IconAddCircle from "@material-ui/icons/AddCircle";
import IconAddUser from "@material-ui/icons/PersonAdd";
import IconAddGroup from "@material-ui/icons/GroupAdd";
import IconApps from "@material-ui/icons/Apps";
import IconArrowDown from "@material-ui/icons/KeyboardArrowDown";
import IconArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconArrowLeft from "@material-ui/icons/ChevronLeft";
import IconArchive from "@material-ui/icons/Archive";
import IconAttachment from "@material-ui/icons/AttachFile";
import IconActivity from "@material-ui/icons/BubbleChart";
import IconBlock from "@material-ui/icons/Block";
import IconBoard from "@material-ui/icons/ViewCompact";
import IconCalendar from "@material-ui/icons/CalendarToday";
import IconCalendar3 from "@material-ui/icons/DateRange";
import IconCancel from "@material-ui/icons/Cancel";
import IconCheck from "@material-ui/icons/Check";
import IconCheck3 from "@material-ui/icons/CheckCircleOutline";
import IconCheckUncheck from "@material-ui/icons/Flaky";
import IconClass from "@material-ui/icons/LibraryBooks";
import IconClose from "@material-ui/icons/Close";
import IconCheckBox from "@material-ui/icons/CheckBox";
import IconCheckBoxBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import IconCheckCircle from "@material-ui/icons/CheckCircleOutline";
import IconCopy from "@material-ui/icons/FileCopy";
import IconCourse from "@material-ui/icons/CollectionsBookmark";
import IconContact from "@material-ui/icons/PermContactCalendar";
import IconChat from "@material-ui/icons/Forum";
import IconDescription from "@material-ui/icons/Description";
import IconPoll from "@material-ui/icons/PieChart";
import IconCurriculum from "@material-ui/icons/CastForEducation";
import IconDelete from "@material-ui/icons/Delete";
import IconDownload from "@material-ui/icons/CloudDownload";
import IconDone from "@material-ui/icons/Done";
import IconDoneAll from "@material-ui/icons/DoneAll";
import IconDrag from "@material-ui/icons/DragIndicator";
import IconDrive from "@material-ui/icons/CloudQueue";
import IconEdit from "@material-ui/icons/Edit";
import IconEvent from "@material-ui/icons/Event";
import IconExport from "@material-ui/icons/ImportExport";
import IconExpand from "@material-ui/icons/ExpandMore";
import IconExam from "@material-ui/icons/FactCheck";
import IconFile from "@material-ui/icons/InsertDriveFile";
import IconFilter from "@material-ui/icons/FilterList";
import IconFolder from "@material-ui/icons/Folder";
import IconFolderCreate from "@material-ui/icons/CreateNewFolder";
import IconGrade from "@material-ui/icons/Grade";
import IconGroups from "@material-ui/icons/Group";
import IconGroups2 from "@material-ui/icons/GroupWork";
import IconGrid from "@material-ui/icons/GridOn";
import IconHelp from "@material-ui/icons/Help";
import IconHome from "@material-ui/icons/Home";
import IconHourglass from "@material-ui/icons/HourglassEmpty";
import IconInfoboard from "@material-ui/icons/Info";
import IconInfo from "@material-ui/icons/Info";
import IconInbox from "@material-ui/icons/MoveToInbox";
import IconImage from "@material-ui/icons/Image";
import IconImportExport from "@material-ui/icons/ImportExport";
import IconList from "@material-ui/icons/List";
import IconLink from "@material-ui/icons/Link";
//import IconLesson from "@material-ui/icons/School";
import IconMark from "@material-ui/icons/BookmarkBorder";
import IconMail from "@material-ui/icons/Mail";
import IconMeet from "@material-ui/icons/Duo";
import IconMenu from "@material-ui/icons/Menu";
import IconFlipbook from "@material-ui/icons/AutoStories";
import IconMore from "@material-ui/icons/MoreVert";
import IconMove from "@material-ui/icons/ArrowForwardIos";
import IconNotes from "@material-ui/icons/Notes";
import IconNotifications from "@material-ui/icons/Notifications";
import IconPending from "@material-ui/icons/HourglassEmpty";
//import IconProject from "@material-ui/icons/Work";
import IconPdf from "@material-ui/icons/PictureAsPdf";
import IconPreview from "@material-ui/icons/Preview";
import IconRefresh from "@material-ui/icons/Refresh";
import IconPublish from "@material-ui/icons/Publish";
import IconPostAdd from "@material-ui/icons/PostAdd";
import IconReply from "@material-ui/icons/Reply";
import IconSave from "@material-ui/icons/Save";
import IconShare from "@material-ui/icons/Share";
import IconSearch from "@material-ui/icons/Search";
import IconSend from "@material-ui/icons/Send";
import IconStats from "@material-ui/icons/Equalizer";
import IconStar from "@material-ui/icons/Star";
import IconStarEmpty from "@material-ui/icons/StarOutline";
import IconStarHalf from "@material-ui/icons/StarHalf";
import IconTask from "@material-ui/icons/EventAvailable";
import IconTasks from "@material-ui/icons/AssignmentTurnedIn";
import IconTest from "@material-ui/icons/Help";
import IconTextFields from "@material-ui/icons/TextFields";
import IconTimeline from "@material-ui/icons/Timeline";
import IconTitle from "@material-ui/icons/Title";
import IconToggle from "@material-ui/icons/ToggleOn";
import IconTimer from "@material-ui/icons/Timer";
import IconQueue from "@material-ui/icons/Queue";
import IconUser from "@material-ui/icons/Person";
import IconUpload from "@material-ui/icons/CloudUpload";
import IconSettings from "@material-ui/icons/Settings";
import IconSigma from "@material-ui/icons/Functions";
import IconSubject from "@material-ui/icons/Subject";
import IconVideo from "@material-ui/icons/Videocam";
import IconVideomeet from "@material-ui/icons/Videocam";
import IconVisibility from "@material-ui/icons/Visibility";
import IconVisibilityOff from "@material-ui/icons/VisibilityOff";
import IconZoomIn from "@material-ui/icons/ZoomIn";

import IconVolumeUp from "@material-ui/icons/VolumeUp";
import IconVolumeOff from "@material-ui/icons/VolumeOff";

import { FontAwesomeIcon as FontAwesomeIconBase } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
// custom styling

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 19
  }
}));

const FontAwesomeIcon = props => {
  const classes = useStyles();

  return <FontAwesomeIconBase className={classes.icon} {...props} />;
};

const IconFileImport = () => {
  return <FontAwesomeIcon icon="file-import" />;
};
const IconFileExport = () => {
  return <FontAwesomeIcon icon="file-export" />;
};
const IconSignInAlt = () => {
  return <FontAwesomeIcon icon="sign-in-alt" />;
};
const IconGoogle = () => {
  return <FontAwesomeIcon icon={["fab", "google"]} />;
};
const IconFacebook = () => {
  return <FontAwesomeIcon icon={["fab", "facebook-f"]} />;
};
const IconCalendar2 = () => {
  return <FontAwesomeIcon icon={["fas", "calendar-alt"]} />;
};
const IconAccount2 = () => {
  return <FontAwesomeIcon icon={["fas", "id-card"]} />;
};
const IconLesson = () => {
  return <FontAwesomeIcon icon={["fas", "book-reader"]} />;
};
const IconMegaphone = () => {
  return <FontAwesomeIcon icon={["fas", "bullhorn"]} />;
};
const IconUserCheck = () => {
  return <FontAwesomeIcon icon={["fas", "user-check"]} />;
};
const IconUserTie = () => {
  return <FontAwesomeIcon icon={["fas", "user-tie"]} />;
};
const IconEmployee = () => {
  return <FontAwesomeIcon icon={["fas", "user-tie"]} />;
};
const IconUsers = () => {
  return <FontAwesomeIcon icon={["fas", "users"]} />;
};
const IconRocket = () => {
  return <FontAwesomeIcon icon={["fas", "rocket"]} />;
};
const IconFileWord = () => {
  return <FontAwesomeIcon icon={["fas", "file-word"]} />;
};
const IconFileExcel = () => {
  return <FontAwesomeIcon icon={["fas", "file-excel"]} />;
};
const IconFilePdf = () => {
  return <FontAwesomeIcon icon={["fas", "file-pdf"]} />;
};
const IconFilePowerpoint = () => {
  return <FontAwesomeIcon icon={["fas", "file-powerpoint"]} />;
};
const IconFileVideo = () => {
  return <FontAwesomeIcon icon={["fas", "file-video"]} />;
};
const IconFileAlt = () => {
  return <FontAwesomeIcon icon={["fas", "file-alt"]} />;
};
const IconChartLine = () => {
  return <FontAwesomeIcon icon={["fas", "chart-line"]} />;
};
const IconChecklist = () => {
  return <FontAwesomeIcon icon={["fas", "tasks"]} />;
};
const IconThumbs = () => {
  return <FontAwesomeIcon icon={["fas", "th-large"]} />;
};

const IconProject = props => {
  var { size, ...other } = props;

  //console.log (props);
  var fancyClass = props.checked ? "FancyChecked" : "FancyUnchecked";
  return (
    <FontAwesomeIcon
      {...other}
      icon={["fas", "business-time"]}
      style={{ marginRight: 2 }}
    />
  );
};

export {
  IconFileImport,
  IconFileExport,
  IconFileExcel,
  IconFileWord,
  IconFilePdf,
  IconFilePowerpoint,
  IconFileVideo,
  IconFileAlt,
  IconSignInAlt,
  IconAccount,
  IconAccount2,
  IconAlarm,
  IconAlarmAdd,
  IconArrowDown,
  IconArrowRight,
  IconArrowLeft,
  IconAdd,
  IconAddCircle,
  IconAddGroup,
  IconAddUser,
  IconAll,
  IconAttachment,
  IconArchive,
  IconApps,
  IconActivity,
  IconBoard,
  IconBlock,
  IconCalendar,
  IconCalendar2,
  IconCalendar3,
  IconChartLine,
  IconCheck,
  IconCancel,
  IconCheck3,
  IconCheckBox,
  IconCheckBoxBlank,
  IconCheckCircle,
  IconChecklist,
  IconCheckUncheck,
  IconClass,
  IconClose,
  IconCurriculum,
  IconCopy,
  IconCourse,
  IconChat,
  IconContact,
  IconDelete,
  IconDescription,
  IconDrag,
  IconDownload,
  IconDone,
  IconDoneAll,
  IconDrive,
  IconEdit,
  IconExport,
  IconExam,
  IconFile,
  IconFlipbook,
  IconFolder,
  IconFolderCreate,
  IconGroups,
  IconGoogle,
  IconGrid,
  IconGrade,
  IconEmployee,
  IconEvent,
  IconExpand,
  IconFacebook,
  IconFilter,
  IconGroups2,
  IconHelp,
  IconHome,
  IconHourglass,
  IconInfo,
  IconInbox,
  IconInfoboard,
  IconImportExport,
  IconImage,
  IconList,
  IconLesson,
  IconLink,
  IconMail,
  IconMark,
  IconMegaphone,
  IconMenu,
  IconMeet,
  IconMore,
  IconMove,
  IconNotes,
  IconNotifications,
  IconPdf,
  IconPending,
  IconPoll,
  IconProject,
  IconPreview,
  IconPublish,
  IconPostAdd,
  IconRefresh,
  IconReply,
  IconRocket,
  IconSave,
  IconSettings,
  IconSearch,
  IconSend,
  IconShare,
  IconSigma,
  IconStats,
  IconStar,
  IconStarEmpty,
  IconStarHalf,
  IconSubject,
  IconTest,
  IconTask,
  IconTasks,
  IconThumbs,
  IconTextFields,
  IconTimeline,
  IconTimer,
  IconTitle,
  IconToggle,
  IconQueue,
  IconUser,
  IconUserTie,
  IconUsers,
  IconUserCheck,
  IconUpload,
  IconVideo,
  IconVideomeet,
  IconVisibility,
  IconVisibilityOff,
  IconVolumeUp,
  IconVolumeOff,
  IconZoomIn
};
