import React from "react";

// REDUX REDUCERS
import {
  SIDEBAR_LEFT_OPEN,
  SIDEBAR_LEFT_CLOSE,
  SIDEBAR_RIGHT_OPEN,
  SIDEBAR_RIGHT_CLOSE,
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE
} from "../constants";

const getSidebarStatus = () => {
  if (localStorage.getItem("sidebarOpen") == null) {
    localStorage.getItem("sidebarOpen");
    return true;
  } else {
    return localStorage.getItem("sidebarOpen");
  }
};

const initialState = {
  open: JSON.parse(getSidebarStatus()),
  title: "",
  version: 0,
  component: null,
  left: {
    width: 300,
    open: false,
    title: "",
    component: <div></div>
  },
  right: {
    width: 300,
    open: false,
    title: "",
    sidebarProps: {
      isFreeComponent: false
    },
    component: <div></div>
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_LEFT_OPEN:
      localStorage.setItem("sidebarOpen", true);
      return {
        ...state,
        left: {
          open: true,
          title: action.payload.title,
          component: action.payload.component
        }
      };
    case SIDEBAR_LEFT_CLOSE:
      return { ...state, left: initialState.left };

    case SIDEBAR_RIGHT_OPEN:
      //console.log (action.payload);
      return {
        ...state,
        right: {
          open: true,
          title: action.payload.title,
          sidebarProps:
            action.payload.sidebarProps !== undefined
              ? action.payload.sidebarProps
              : initialState.right.sidebarProps,
          component: action.payload.component
        }
      };
    case SIDEBAR_RIGHT_CLOSE:
      return { ...state, right: initialState.right };

    case SIDEBAR_OPEN:
      localStorage.setItem("sidebarOpen", true);
      return { ...state, open: true };

    case SIDEBAR_CLOSE:
      localStorage.setItem("sidebarOpen", false);
      return { ...state, open: false };
    default:
      return state;
  }
};
