import React from "react";
// REDUX REDUCERS
import {
  FETCH_CONTENT,
  FETCH_CONTENT_SUCCESS,
  FETCH_CONTENT_FAILURE,
  FETCH_CONTENT_LIST,
  FETCH_CONTENT_LIST_SUCCESS,
  FETCH_CONTENT_LIST_FAILURE,
  //DELETE_CONTENT,
  DELETE_CONTENT_SUCCESS,
  //DELETE_CONTENT_FAILURE,
  CONTENT_LIST_FILTER
} from "../constants";

import {
  IconChecklist,
  IconLesson,
  IconProject,
  IconFlipbook
} from "components/Icons";

const initialState = {
  list: {
    filter: "",
    data: [],
    loading: false,
    error: false
  },
  content: {
    data: {},
    list: [],
    loading: false,
    error: false,
    saving: false
  },

  types: (type, group = "main") => {
    // group == main, extended

    //console.log(type);

    let typeList = [
      {
        group: "other",
        type: "default",
        title: "Unknown",
        icon: "icon-question3",
        iconm: "help"
      },
      {
        group: "main",
        type: "assignment",
        title: "Task",
        icon: "icon-file-check2",
        iconm: "assignment_turned_in"
      },
      {
        group: "main",
        type: "flipbook",
        title: "Flipbook",
        iconMui: <IconFlipbook />
      },
      {
        group: "main",
        type: "task",
        title: "Task",
        titlePlaceholder: "Task name",
        icon: "icon-file-check2",
        iconm: "assignment_turned_in"
      },
      {
        group: "main",
        type: "checklist",
        grading: true,
        title: "Checklist",
        titlePlaceholder: "Checklist name",
        iconm: "list",
        iconMui: <IconChecklist />
      },
      {
        group: "main",
        type: "list",
        title: "Tasks",
        titlePlaceholder: "Task list name",
        icon: "icon-stack-check",
        iconm: "list"
      },
      {
        group: "main",
        type: "text",
        title: "Text",
        titlePlaceholder: "Text title",
        icon: "icon-file-text2",
        iconm: "description"
      },
      {
        group: "main",
        type: "notification",
        title: "Notification",
        titlePlaceholder: "Notification title",
        icon: "icon-bell2",
        iconm: "notifications"
      },
      {
        group: "main",
        type: "pool",
        title: "Pool",
        titlePlaceholder: "Pool title",
        icon: "icon-pie-chart3",
        iconm: "pie_chart"
      },
      {
        group: "main",
        type: "poll",
        title: "Poll",
        titlePlaceholder: "Poll title",
        iconm: "poll"
      },
      {
        group: "main",
        type: "note",
        title: "Note",
        titlePlaceholder: "Note title",
        icon: "icon-notebook",
        iconm: ""
      },
      {
        group: "extended",
        type: "chapter",
        title: "Chapter",
        titlePlaceholder: "Chapter title",
        icon: "",
        iconm: "view_agenda"
      },
      {
        group: "extended",
        type: "lesson",
        title: "Lesson",
        titlePlaceholder: "Lesson title",
        icon: "icon-stack-text",
        iconm: "school",
        iconMui: <IconLesson />
      },
      {
        group: "extended",
        type: "curriculum",
        title: "Curriculum",
        icon: "icon-stack",
        iconm: "view_module"
      },
      {
        group: "extended",
        type: "project",
        title: "Project",
        titlePlaceholder: "Project name",
        icon: "icon-portfolio",
        iconm: "work",
        iconMui: <IconProject />
      },
      {
        group: "extended",
        type: "test",
        title: "Test",
        titlePlaceholder: "Test title",
        icon: "icon-stack-check",
        iconm: "help"
      },
      {
        group: "extended",
        type: "group",
        title: "Group",
        titlePlaceholder: "Group name",
        icon: "icon-people",
        iconm: "group"
      } /*,
      {
        group: "extended",
        type: "class",
        title: "Class",
        icon: "icon-people",
        iconm: "group"
      }*/,
      {
        group: "extended",
        type: "class",
        title: "Class",
        titlePlaceholder: "Class title",
        icon: "icon-people",
        iconm: "library_books"
      },
      {
        group: "extended",
        type: "activity_group",
        title: "Activity group",
        titlePlaceholder: "Activity group title",
        icon: "icon-people",
        iconm: "bubble_chart"
      },
      {
        group: "extended",
        type: "document",
        title: "Document",
        icon: "icon-people",
        iconm: "insert_drive_file"
      }
    ];

    if (type) {
      let result = typeList.find(ty => ty.type === type);

      if (undefined === result) {
        result = typeList[0];
      }

      return result;
    } else {
      return typeList;
    }
  },

  score_types: score => {
    var score = parseInt(score);

    let score_types = [
      {
        id: 10,
        type: "text",
        title: "Text",
        mark: "text",
        inputType: "textarea",
        group: "score"
        //'range' : [10,9,8,7,6,5,4,3,2,1],
      },
      {
        id: 9,
        type: "check",
        title: "Check",
        mark: "+ / -",
        inputType: "checkbox",
        range: ["done", "not done"],
        icon: '<span class="icon-stack-check"></span>',
        group: "score"
      },
      {
        id: 7,
        type: "points",
        title: "Points",
        mark: "pt",
        inputType: "input",
        range: [1, 900],
        group: "score"
      },
      {
        id: 6,
        type: "percent",
        title: "Percent",
        mark: "%",
        inputType: "input",
        range: [1, 100],
        group: "score"
      },
      {
        id: 13,
        type: "marks",
        title: "Marks",
        mark: "",
        inputType: "",
        group: "score"
        //'range' : [10,9,8,7,6,5,4,3,2,1],
      },
      {
        id: 15,
        type: "test",
        title: "Test",
        mark: "",
        inputType: "",
        group: "score"
        //'range' : [10,9,8,7,6,5,4,3,2,1],
      }
    ];

    if (score) {
      let result = score_types.find(ty => ty.id === score);
      return result;
    } else {
      return score_types;
    }
  },

  score_allowed: [
    { id: 10, linked: [10, 6, 7, 9, 13, 15] },
    { id: 9, linked: [10, 6, 7, 9, 13, 15] },
    { id: 7, linked: [10, 7, 9, 15] },
    { id: 6, linked: [10, 6, 9, 15] },
    { id: 13, linked: [10, 6, 9, 13, 15] },
    { id: 15, linked: [10, 9, 15] }
  ],

  grade_types: grade => {
    var grade = parseInt(grade);

    if (grade === 12) {
      grade = 16;
    }
    let grade_types = [
      /*{
      'type': 'trueFalse',
      'title' : 'Done / Not Done',
      'inputType' : 'dropdown',
      'range' : [ 'done', 'not done'],
      'icon' : '<span class="icon-stack-check"></span>'
    },*/
      /*{
      'type':'points',
      'group' : 'Scoring',
      'title' : 'points',
      'inputType' : 'input',
      'range' : [1,900],
    },
    {
    'type':'percent',
      'group' : 'Scoring',
      'title' : '%',
      'inputType' : 'input',
      'range' : [1,100],
    },*/

      {
        id: 16,
        type: "check-only",
        group: "grade",
        title: "Check only",
        inputType: "checkbox",
        icon: "check_box",
        range: null
      },
      {
        id: 19,
        type: "percent",
        group: "grade",
        title: "Percent",
        inputType: "input",
        range: [1, 100],
        mark: "%"
      },
      {
        id: 20,
        type: "points",
        group: "grade",
        title: "Points",
        inputType: "input",
        range: [1, 900],
        mark: "pt"
      },
      {
        id: 4,
        type: "AtoF",
        group: "grade",
        title: "Grade A-F",
        subtitle: "A-F",
        inputType: "dropdown",
        range: ["A", "B", "C", "D", "E", "F"]
      },
      {
        id: 5,
        type: "1to5",
        group: "grade",
        title: "Grade 1-5",
        subtitle: "1-5",
        inputType: "dropdown",
        range: [5, 4, 3, 2, 1]
      },
      {
        id: 8,
        type: "1to10",
        group: "grade",
        title: "Grade 1-10",
        subtitle: "1-10",
        inputType: "dropdown",
        range: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
      } /*,
      {
        id: 12,
        type: "no_rating",
        group: "grade",
        title: "No rating",
        inputType: null,
        range: null
      }*/
    ];

    if (grade) {
      let result = grade_types.find(ty => ty.id === grade);
      return result;
    } else {
      return grade_types;
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    // content list
    case FETCH_CONTENT_LIST:
      return {
        ...state,
        list: {
          data: [],
          error: false,
          loading: true,
          filter: state.list.filter
        }
      };

    case FETCH_CONTENT_LIST_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload,
          loading: false,
          error: false,
          filter: state.list.filter
        }
      };

    case FETCH_CONTENT_LIST_FAILURE:
      return {
        ...state,
        list: {
          data: [],
          loading: false,
          error: true,
          filter: state.list.filter
        }
      };

    // content entity
    case FETCH_CONTENT:
      return { ...state, content: { data: {}, error: false, loading: true } };

    case FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        content: { data: action.payload, loading: false, error: false }
      };

    case FETCH_CONTENT_FAILURE:
      return { ...state, content: { data: {}, loading: false, error: true } };

    case CONTENT_LIST_FILTER: {
      var list = { ...state.list };
      list.filter = action.payload;
      return { ...state, list: list };
    }

    // delete content
    case DELETE_CONTENT_SUCCESS: {
      // remove content from list
      let id = action.payload;
      let findIndex = state.list.data.findIndex(cl => cl.id === id);
      let contentList = [...state.list.data];

      //console.log(id, contentList);

      if (findIndex !== -1) {
        contentList.splice(findIndex, 1);
      }

      return {
        ...state,
        list: {
          data: contentList,
          loading: false,
          error: false,
          filter: state.list.filter
        }
      };
    }

    default:
      return state;
  }
};
