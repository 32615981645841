// REDUX REDUCERS
import {
  FETCH_PROJECT_TIMELINE,
  FETCH_PROJECT_TIMELINE_SUCCESS,
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  SET_PROJECT_TAB,
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE
} from "../constants.js";

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null
  },
  single: {
    tab: "board",
    data: {},
    loading: false,
    error: null
  },
  timeline: {
    data: [],
    loading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return { ...state, list: { ...state.list, loading: true, error: null } };

    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        list: { data: action.payload, loading: false, error: null }
      };

    case FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        list: { error: action.payload, loading: false, data: [] }
      };

    case FETCH_PROJECT:
      return { ...state, single: { data: {}, loading: true, error: null } };

    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        single: { data: action.payload, loading: false, error: null }
      };

    case FETCH_PROJECT_FAILURE:
      return {
        ...state,
        single: { error: action.payload, loading: false, data: {} }
      };

    case SET_PROJECT_TAB:
      return { ...state, single: { ...state.single, tab: action.payload } };

    case FETCH_PROJECT_TIMELINE:
      return { ...state, timeline: { ...state.timeline, loading: true } };

    case FETCH_PROJECT_TIMELINE_SUCCESS:
      return { ...state, timeline: action.payload };

    default:
      return state;
  }
};
