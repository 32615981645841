import {
  FETCH_APPLICATION_PROFILE,
  FETCH_APPLICATION_PROFILE_SUCCESS,
  FETCH_APPLICATION_PROFILE_FAILURE,
  APP_SET_ONLINE
} from "../constants.js";

const initialState = {
  profile: {
    display_name: "",
    initials: "",
    allowLogin: false,
    allowRegister: false,
    footer: true,
    header: true,
    sidebar: true,
    logo: false
  },
  online: true,
  loading: false,
  error: null
};

const applicationReducer = (state = initialState, action) => {
  //console.log('>>>',action);

  switch (action.type) {
    case FETCH_APPLICATION_PROFILE:
      return { ...state, loading: true, error: null };

    case FETCH_APPLICATION_PROFILE_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
        error: null,
        loading: false
      };

    case FETCH_APPLICATION_PROFILE_FAILURE:
      return {
        ...state,
        profile: initialState.profile,
        error: action.payload,
        loading: false
      };
    case APP_SET_ONLINE:
      return {
        ...state,
        online: action.payload
      };

    default:
      return state;
  }
};

export default applicationReducer;
