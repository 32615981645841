import { OPEN_DIALOG, CLOSE_DIALOG, UPDATE_DIALOG } from "../constants";

const initialDialog = {
  open: false,
  title: "",
  actions: false,
  actionsShowCloseButton: true,
  component: null,
  maxWidth: "md", // modal-lg , modal-full,
  fullScreen: false,
  fade: false,
  closeButton: true,
  headerClass: "",
  bodyClass: "pt-1",
  removeContainer: false,
  actionButtons: false,
  disableOptions: true
};

function dialogReducer(state = initialDialog, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      action.payload.open = true;
      return { ...state, ...initialDialog, ...action.payload };

    case UPDATE_DIALOG:
      return { ...state, ...action.payload };

    case CLOSE_DIALOG:
      return initialDialog;
      break;

    default:
      return state;
  }
}

export default dialogReducer;
