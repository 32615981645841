// REDUX REDUCERS
import {
  FETCH_CHANNEL,
  FETCH_CHANNEL_SUCCESS,
  FETCH_CHANNEL_FAILURE,
  FETCH_CHANNEL_LIST,
  FETCH_CHANNEL_LIST_SUCCESS,
  FETCH_CHANNEL_LIST_FAILURE
} from "../constants.js";

const initialState = {
  error_list: null,
  loading_list: false,
  list: [],
  error_single: null,
  loading_single: false,
  single: {
    messages: [],
    data: {},
    last_updated: null,
    account_code: null,
    participants: []
  }
};

const messengerReducer = (state = initialState, action) => {
  //console.log(action.type);

  switch (action.type) {
    case FETCH_CHANNEL:
      return { ...state, loading_single: true, error_single: null };

    case FETCH_CHANNEL_SUCCESS: {
      /*if(action.payload.data.last_updated !== state.single.data.last_updated) {
        console.log('1111:',action.payload.data.last_updated);
        console.log('2222:', state.single.data.last_updated);*/
      return {
        ...state,
        single: action.payload,
        error_single: null,
        loading_single: false
      };
      /*}
      else {
        console.log('3333:', state.single.data.last_updated);
        return {...state}
      }*/
    }

    case FETCH_CHANNEL_FAILURE: // return error and make loading = false
      return {
        ...state,
        single: null,
        error_single: action.payload,
        loading_single: false
      };

    case FETCH_CHANNEL_LIST:
      return { ...state, loading_list: true, error_list: null };

    case FETCH_CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error_list: null,
        loading_list: false
      };

    case FETCH_CHANNEL_LIST_FAILURE: // return error and make loading = false
      return {
        ...state,
        list: null,
        error_list: action.payload,
        loading_list: false
      };

    default:
      return state;
  }
};

export default messengerReducer;
