import { OPEN_MODAL, CLOSE_MODAL } from "../constants";

const initialModal = {
  open: false,
  title: "",
  component: null,
  maxWidth: "md", // modal-lg , modal-full,
  fullScreen: false,
  fade: false,
  closeButton: true,
  headerClass: "",
  bodyClass: "pt-1",
  removeContainer: false,
  actionButtons: true
};

function modalReducer(state = initialModal, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      action.payload.open = true;
      return { ...state, ...initialModal, ...action.payload };
    }

    case CLOSE_MODAL:
      return initialModal;

    default:
      return state;
  }
}

export default modalReducer;
