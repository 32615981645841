// REDUX REDUCERS

import {
  FETCH_ACTIVITY_SINGLE,
  FETCH_ACTIVITY,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_FAILURE,
  MARK_ACTIVITY_AS_VIEWED,
  FETCH_ACTIVITY_SINGLE_SUCCESS
} from "../constants.js";

import produce from "immer";

const initialState = {
  single: {},
  activity: [],
  lastActivityId: null,
  loading: false,
  loading_single: false
};

const activityReducer = (state = initialState, action) => {
  //console.log('>>>',action);

  switch (action.type) {
    case FETCH_ACTIVITY_SINGLE_SUCCESS:
      return { ...state, single: action.payload };

    case FETCH_ACTIVITY:
      return { ...state, loading: true };

    case FETCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.payload.activity,
        lastActivityId: action.payload.lastActivityId,
        loading: false
      };

    case FETCH_ACTIVITY_FAILURE: // return error and make loading = false
      return { ...state, activity: [], loading: false };

    case MARK_ACTIVITY_AS_VIEWED: {
      return produce(state, draft => {
        let index = draft.activity.findIndex(p => p.id == action.id);
        if (index !== undefined) {
          draft.activity.splice(index, 1);
        }
      });
    }

    default:
      return state;
  }
};

export default activityReducer;
