import {
  OPEN_MEET,
  CLOSE_MEET,
  MEET_LINKS_LOADING,
  FETCH_MEET_LINKS,
  CLEAR_MEET_LINKS
} from "../constants";

const initialState = {
  data: {},
  token: "",
  enabled: false,
  links: [], // my meet links
  linksLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MEET: {
      return {
        ...state,
        data: action.payload,
        enabled: true
      };
      break;
    }
    case CLOSE_MEET: {
      return initialState;
      break;
    }
    case FETCH_MEET_LINKS: {
      return {
        ...state,
        links: action.payload
      };
      break;
    }
    case CLEAR_MEET_LINKS: {
      return {
        ...state,
        links: []
      };
      break;
    }
    case MEET_LINKS_LOADING: {
      return {
        ...state,
        linksLoading: action.payload
      };
      break;
    }
    default:
      return state;
  }
};
//export default rootReducer;
