// REDUX STORE

import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { createBrowserHistory } from "history";
import promise from "redux-promise";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";
import { routerMiddleware } from "connected-react-router";

// Middleware you want to use in production:
const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error
});

const history = createBrowserHistory();

//const reduxLogger = __REDUXLOGGER__;

//console.log(reduxLogger);
//const enhancer = applyMiddleware(thunk, promise, logger, routerMiddleware(history));
const enhancer = applyMiddleware(thunk, promise, routerMiddleware(history));

//const router = routerMiddleware(history);

const store = createStore(createRootReducer(history), compose(enhancer));

export default store;
