// REDUX REDUCERS
import {
  SEARCH_DRIVE,
  SET_SELECTED_DRIVE,
  FETCH_DRIVE,
  FETCH_DRIVE_SUCCESS,
  FETCH_DRIVE_FAILURE
} from "../constants.js";

const initialState = {
  data: {
    items: [],
    dirtree: []
  },
  searchfor: "",
  dirid: null,
  loading: false,
  error: null,
  selected: []
};

export default (state = initialState, action) => {
  //console.log (action.payload);
  switch (action.type) {
    case FETCH_DRIVE:
      return { ...state, loading: true, error: null };

    case SEARCH_DRIVE:
      return { ...state, searchfor: action.searchfor };

    case SET_SELECTED_DRIVE:
      return { ...state, selected: action.selected };

    case FETCH_DRIVE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        dirid: action.payload.dirid,
        error: null,
        loading: false
      };

    case FETCH_DRIVE_FAILURE: // return error and make loading = false
      return { ...state, data: null, error: action.payload, loading: false };

    default:
      return state;
  }
};
