import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  ME_FROM_TOKEN,
  ME_FROM_TOKEN_SUCCESS,
  ME_FROM_TOKEN_FAILURE,
  RESET_ME_A,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE
} from "../constants.js";

const initialState = {
  token: localStorage.getItem("jwtToken", null),
  data: {},
  data_full: {},
  error: null,
  loading: false,
  isAuthenticated: false,
  register: {},
  recoverPasswordSend: false,
  recoverPasswordError: false,
  acl: {}
};

const accountReducer = (state = initialState, action) => {
  //console.log('>>>',action);

  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: null };

    case LOGIN_USER_SUCCESS:
      //localStorage.setItem('jwtToken', action.payload.token);
      //console.log("Login user success:", action.payload);
      return {
        ...state,
        token: action.payload,
        error: null,
        loading: false
      };

    case LOGIN_USER_FAILURE: // return error and make loading = false
      return {
        ...state,
        token: null,
        error: action.payload,
        loading: false,
        isAuthenticated: false
      };

    case RESET_ME_A:
      return {
        ...state,
        acl: {},
        data: {},
        token: null,
        error: null,
        loading: false,
        isAuthenticated: false
      };

    case ME_FROM_TOKEN:
      return { ...state, loading: true };

    case ME_FROM_TOKEN_SUCCESS:
      //console.log ('AAAAPPPLL', action.payload)
      return {
        ...state,
        acl: action.payload.acl,
        data: action.payload.user,
        error: null,
        isAuthenticated: true,
        loading: false
      };

    case ME_FROM_TOKEN_FAILURE: // return error and make loading = false
      //console.log(action.payload);
      //error = action.payload || { message: action.payload.message };
      return {
        ...state,
        data: {},
        acl: {},
        token: null,
        error: action.payload,
        isAuthenticated: false,
        loading: false
      };

    case FETCH_ACCOUNT:
      //return { ...state, loading: true };
      return state;

    case FETCH_ACCOUNT_SUCCESS:
      return { ...state, data_full: action.payload.user };

    case FETCH_ACCOUNT_FAILURE: // return error and make loading = false
      return { ...state, data_full: {} };

    case RECOVER_PASSWORD:
      return {
        ...state,
        recoverPasswordSend: false,
        recoverPasswordError: false
      };

    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        recoverPasswordSend: true,
        recoverPasswordError: false
      };

    case RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        recoverPasswordSend: true,
        recoverPasswordError: true
      };

    default:
      return state;
  }
};

export default accountReducer;
